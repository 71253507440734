import router from '../../../router/index';
import { HIDE_TASK_STATUSES } from '@/helpers/constants';

export default {
  setDraggedUsers({ commit }, payload) {
    commit('SET_DRAGGED_USERS', payload);
  },

  async setTasksDisplayed({ commit, dispatch, rootGetters, state }, payload) {
    const statBefore = state.tasksDisplayed;

    commit('SET_TASKS_DISPLAYED', payload);

    const statAfter = state.tasksDisplayed;

    const li = rootGetters['lists/selectedList'];

    if (li) {
      const statusTofetch = HIDE_TASK_STATUSES.map(st => {
        if (statBefore[st] && !statAfter[st]) {
          return HIDE_TASK_STATUSES.indexOf(st) + 1;
        }
      }).filter(s => !!s)[0];

      const usersSelected = rootGetters['tasksState/selectedUsers'];
      let isUserChosen = false;
      await usersSelected.forEach(async user => {
        isUserChosen = li.users.some(u => u.id === user);
        if (isUserChosen) {
          await dispatch('tasks/fetchTasksByUser', [li, user, statusTofetch], {
            root: true,
          });
        }
      });

      if (!isUserChosen && statusTofetch) {
        dispatch('tasks/fetchTasksByStatus', [li, statusTofetch], {
          root: true,
        });
      }
    }
  },

  setSelectedUsers({ commit, dispatch, rootGetters }, payload) {
    commit('SET_SELECTED_USERS', payload || []);

    const selList = rootGetters['lists/selectedList'];

    dispatch('tasks/getTasksForList', selList, { root: true });
  },

  toggleAddedTask({ commit }) {
    commit('TOGGLE_ADDED_TASK');
  },

  toggleAddEditTask({ commit, rootGetters, state, dispatch }, payload) {
    if (state.taskChanges.length > 0) {
      return;
    } else {
      commit('SET_TASK_ADD_EDIT_STATE', payload);

      if (payload === false) {
        const toAddTags = rootGetters['categoriesTagsMoods/toAddTags'];
        const toRemoveTags = rootGetters['categoriesTagsMoods/toRemoveTags'];

        if (toAddTags.length + toRemoveTags.length > 0) {
          const taskToUp = rootGetters['lists/allTasks'].find(
            t => t.id === state.taskToEdit.id,
          );

          if (taskToUp) {
            toRemoveTags.forEach(tr => taskToUp.tags.splice(tr.index, 0, tr));

            taskToUp.tags = taskToUp.tags.filter(
              t => !toAddTags.includes(t.tag),
            );

            dispatch('tasks/updateTaskInList', taskToUp, { root: true });
          }

          commit('categoriesTagsMoods/SET_TO_ADD_TAGS', [], { root: true });
          commit('categoriesTagsMoods/SET_TO_RM_TAGS', [], { root: true });
        }

        commit('SET_TASK_TO_EDIT', null);

        const selList = rootGetters['lists/selectedList'];

        if (selList?.project && selList?.id) {
          router
            .push(`/dashboard/${selList.project}/list/${selList.key}`)
            .catch(() => {});
        } else {
          router.push('/dashboard/Project-Portfolio');
        }
      }
    }
  },

  async setTaskToEdit({ commit, dispatch, rootGetters, state }, payload) {
    if (state.taskChanges.length > 0) {
      return;
    } else {
      const task = Array.isArray(payload) ? payload[0] : payload;
      const query = Array.isArray(payload) ? payload[1] : undefined;

      if (task) {
        if (task.tags.length === 0) {
          await dispatch('tasks/fetchTaskTags', task, { root: true });
        }
        const epics = rootGetters['epics/selectedProjectEpics'];
        if (task.epic && !epics.find(e => e.id === task.epic)) {
          dispatch('epics/getEpicById', task.epic, { root: true });
        }

        let queryStr =
          !query || !Object.keys(query)
            ? ''
            : `?${Object.keys(query).reduce(
                (acc, key) => acc + key + '=' + query[key] + '&',
                '',
              )}`;
        queryStr = queryStr.endsWith('&') ? queryStr.slice(0, -1) : queryStr;

        const search = rootGetters['searchAll/search'];
        if (search) {
          const taskList = rootGetters['projects/allLists'].find(list => list.id === task.list_id) || null;
          await router.push(
              `/dashboard/${taskList.project}/list/${task.list_key}/task/${task.key}${queryStr}`,
          );
        } else {
          await router.push(
              `/dashboard/${rootGetters['lists/selectedList'].project}/list/${task.list_key}/task/${task.key}${queryStr}`,
          );
        }
      }

      commit('SET_TASK_TO_EDIT', task);
    }
  },

  logout({ commit }) {
    commit('LOGOUT_USER');
  },

  setDraggedAttachment({ commit }, payload) {
    commit('SET_DRAGGED_ATTACH_INTO_DESCRIPTION', payload);
  },
};
